

/* MAIN NAV WITH LOGO */
.navbar {
  background-color:$primary-blue;
  padding-top: 0px;
  padding-bottom: 0px;
}


.mobile-nav {
.navbar-brand {
background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray@2x.png") no-repeat;
background-size: 160px 28px;
&:hover {
background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white@2x.png") no-repeat;
  background-size: 160px 28px;
}
}
}

.navbar {
	border: none;
	margin:0;
	height:65px;
	.container {
		height:65px;
	}
	.navContainer {
		min-height: 100%;
    width:100%;
	}
	.navbar-brand {
		height:50px;
		width:200px;
		background:url('https://makana-health-mc.s3.us-east-2.amazonaws.com/MakanaHealth-primary-logo-02.png') no-repeat;
    background-size: 100%;
	}
	.navbar-nav > li > a.nav-link {
    color:$light-gray;
    font-size: .9rem;
    display: block;
    padding: 1rem 1.5rem;
		&:hover, &:focus {
    	color: $secondary-purple;
    }
    &.current {
      color: $secondary-purple;
    }
  }
  .navbar-collapse.in {
    display: block !important;
}
	.login {
		 margin-left: auto;
  }
  

  .input-group{
    background-color: rgba(255,255,255,.1);
    border-radius: .3rem;
    color: $light-gray;
    border: 0px solid transparent;
  }

  .input-group i{
    color: $light-gray;
  }
  
}


.dropdown-menu{
  margin: .3rem 0 0;
  border-radius: 0px 0px 1px 1px;
 
  a.dropdown-item{
    font-size: .9rem;
    color: $primary-blue;
    &:hover{
      color: $secondary-purple;
    }
  }
}



/* Navigation Button */
a.nav-expander {
	background: none repeat scroll 0 0 $primary-blue;
	color: #FFFFFF;
	display: block;
	font-size: 20px;
	font-weight: 400;
	height: 65px;
	margin: 0;
	padding: 1em 1em 2em 2em;
	position: absolute;
	right: 0;
	text-decoration: none;
	text-transform: uppercase;
	top: 0;
	transition: right 0.3s ease-in-out 0s;
	width: 80px;
	z-index: 12;
	transition: right 0.3s ease-in-out 0s;
	-webkit-transition: right 0.3s ease-in-out 0s;
	-moz-transition: right 0.3s ease-in-out 0s;
	-o-transition: right 0.3s ease-in-out 0s;
}

a.nav-expander:hover {
  cursor: pointer;
}

a.nav-expander.fixed {
  position: fixed;
}

.nav-expanded a.nav-expander.fixed {
    right: 15em;
}

/*Off Canvas Menu Styling */
.mobile-nav {
  background: #2d2f33;
  display: block;
  height: 100%;
  overflow: auto;
  position: fixed;
  right: -20em;
  font-size: 15px;
  top: 0;
  width: 20em;
  z-index: 2000;

  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s;
  .navbar-brand {
	height: 28px;
	background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray.png") no-repeat;
	width: 160px;
	margin: 20px;
	&:hover {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white.png") no-repeat;
		}
	}
}
.nav-expanded .mobile-nav {
  right: 0;
}

body.nav-expanded {
  margin-left: 0em;
  transition: right 0.4s ease-in-out 0s;
  -webkit-transition: right 0.4s ease-in-out 0s;
  -moz-transition: right 0.4s ease-in-out 0s;
  -o-transition: right 0.4s ease-in-out 0s;
}

#nav-close {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding-right: 10px;
  .glyphicon {
	opacity: 1;
	margin: 0 0.5em 0 0;
	&:hover {
		margin: 0 0.5em 0 0;
	}
  }
}



