/* ======================= COLORS ===================== */

$primary-blue: #3f4760;
$primary-purple: #615686;
$secondary-purple: #aa94c6;

$light-gray: #adb5bd;
$dark-gray: #4f4f4f;
$extra-light-gray: #f2f2f2;

$green: #80ccbe;

$white: #fff;

/* ======================= MODAL ===================== */
//** Modal backdrop background color
$modal-backdrop-bg: #e6e6e6 !default;
$modal-content-bg: $white !default;
$modal-content-border-color: none !default;
$modal-content-border-width: 0px !default;
$modal-content-border-radius: 0rem !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: none !default;

/* ======================= BASE CLASS DEFINITION ===================== */
$fa-font-path: "../webfonts";

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/* ======================= FONTS ===================== */
body {
  font-family: "Open Sans", sans-serif;
  color: $dark-gray;
}

.btn-xs {
  padding: 5px 15px;
  font-size: 11px;
}

/* ======================= TYPOGRAPHY ===================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-blue;
  font-weight: 600;
  a {
    color: $primary-blue;
    &:hover {
      color: $primary-purple;
    }
  }
}

h1 {
  padding: 25px 0;
}

h1.display-4 {
  font-weight: 600;
}

a {
  transition: all 0.2s ease-in-out;
  color: $primary-blue;
  &:hover,
  &:active {
    text-decoration: none;
    color: $primary-purple;
  }
  &.primary-link {
    color: $primary-purple;
    font-weight: 400;
    &:hover,
    &:active {
      color: $secondary-purple;
    }
  }
  &.secondary-link {
    color: $secondary-purple;
    font-weight: 400;
    &:hover,
    &:active {
      color: $primary-purple;
    }
  }
  &.readmore-link {
    color: $secondary-purple;
    font-weight: 600;
    &:hover,
    &:active {
      color: $primary-purple;
    }
  }
}

span.readmore-link {
  color: $secondary-purple;
  font-weight: 600;
  &:hover,
  &:active {
    color: $primary-purple;
  }
}

.bold-text {
  font-weight: 700;
}

.primary-text {
  color: $primary-purple;
}

.secondary-text {
  color: $secondary-purple;
}

.primary-blue {
  background-color: $primary-blue;
}

.uppercase {
  text-transform: uppercase;
}

/* ====================== BTNS ========================== */
.btn-primary {
  background: $secondary-purple;
  border-color: $secondary-purple;
  &:hover {
    background: $primary-purple;
    border-color: $primary-purple;
  }
  &:focus,
  &:active {
    background: $primary-purple !important;
    border-color: $primary-purple !important;
    box-shadow: none;
  }
}

.btn-dark-purple {
  color: #fff;
  background: $primary-purple;
  border-color: $primary-purple;
  &:hover {
    color: #fff;
    background: $secondary-purple;
    border-color: $secondary-purple;
  }
  &:focus,
  &:active {
    color: #fff;
    background: $secondary-purple !important;
    border-color: $secondary-purple !important;
    box-shadow: none;
  }
}

.btn-secondary {
  background: $secondary-purple;
  border-color: $secondary-purple;
  &:hover {
    background: $green;
    border-color: $green;
  }
  &:focus,
  &:active {
    background: $green !important;
    border-color: $green !important;
    box-shadow: none;
  }
}

.btn-green,
a.btn-green {
  background: $green;
  border-color: $green;
  color: $white;
  &:hover,
  &:active {
    background: $secondary-purple !important;
    border-color: $secondary-purple !important;
    color: $white;
    box-shadow: none;
  }
}

.btn-outline-secondary {
  border: 1px solid #dddbda;
}

.badge {
  margin-right: 0.5rem;
  font-weight: 300;
  font-size: 70%;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-right: 0.4em;
}

.badge-primary {
  background: $secondary-purple;
}

.badge-secondary {
  background: $primary-purple;
}

.badge-success {
  background: $green;
}

.btn[aria-expanded="true"] .fa-plus {
  display: none;
}
.btn[aria-expanded="false"] .fa-minus {
  display: none;
}

/* ====================== ALERTS ========================== */
.alert-primary {
  color: $primary-purple;
  border-color: rgba($color: $primary-purple, $alpha: 0.3);
  background-color: rgba($color: $secondary-purple, $alpha: 0.3);
}

/* ====================== IMAGES ========================== */
.img-responsive {
  margin: 0 auto 0px auto;
}

/* ======================= STRUCTURE ===================== */

.prpl-bg {
  background-color: $primary-purple;
  h1,
  h2 {
    color: $white;
  }
}

.main-content {
  background: $extra-light-gray;
}

.section-hr {
  padding: 3rem 0;
  span {
    background-color: $extra-light-gray;
    position: relative;
    padding-right: 2rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: $primary-blue;
    letter-spacing: 1px;
  }
  &::before {
    display: block;
    content: "";
    border-top: 0.5px solid rgba(63, 71, 95, 0.5);
    height: 1px;
    position: relative;
    top: 13px;
  }
}

.advertisements {
  padding: 50px 0 50px 0;
  img {
    padding: 0 0 25px 0;
  }
}

.cta {
  background-color: $primary-purple;
  padding: 1.25rem;
  h4,
  p,
  a {
    color: $white;
  }
}

.cta.newsletter {
  background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/2020_MK-Newsletter-Icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  .d-sm-block img {
    max-width: 40px;
  }
}

.cta.mobileapp {
  background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/2020_MK-MobileApp-Icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  .d-sm-block img {
    max-width: 40px;
  }
}

.cta.noIcon {
  background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/2021_MK_cta-bg_noIcon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.cta.enrollment {
  background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/2020_MK-Enrollment-Icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  .d-sm-block img {
    max-width: 40px;
  }
}

.cta.prescription {
  background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/Icons/2020_MK_Provider-Icon7.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  .d-sm-block img {
    max-width: 40px;
  }
}

.pdp-image {
  height: 200px;
  background-size: cover;
  padding: 1rem;
}

.pdp-wrapper .accordion {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion {
  border-top: 6px solid $secondary-purple;
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
  .card {
    margin-bottom: 0px;
  }
  .card-header {
    background-color: #ffffff;
    border-bottom: 0px solid rgba(0, 0, 0, 0);
    .btn {
      font-weight: 600;
      color: $primary-blue;
      padding: 0px;
    }
    .fa-plus,
    .fa-minus {
      font-size: 12px;
    }
  }
  .card:not(:first-of-type) .card-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

/* ======================= PRODUCTS ===================== */

.card {
  border-radius: 1px;
  border-width: 0px;
  margin-bottom: 2rem;
}

.card.payer-product {
  border-top: 6px solid $primary-blue;
  .media {
    h5:last-of-type {
      margin-bottom: 0px;
    }
    img {
      height: 52px;
      padding-right: 1.5rem;
      padding-left: 0.5rem;
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.125);
    transition: all 0.2s ease;
  }
}

.card.product-card {
  border-top: 6px solid $white;
  h6 {
    margin-bottom: 0px;
  }
  .product-link {
    font-size: 0.8rem;
    color: $secondary-purple;
  }
  img {
    height: 100px;
    display: block;
  }
  .d-flex {
    flex-direction: row-reverse;
  }
}

.card.product:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.125);
  transition: all 0.2s ease;
}

.product-card:hover {
  border-top: 6px solid $secondary-purple;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.125);
  transition: all 0.2s ease;
}

.card.trial {
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.125);
    transition: all 0.2s ease;
  }
  &.researching {
    border-top: 6px solid $secondary-purple;
    .status {
      color: rgba(255, 255, 255, 0.8);
      background: $secondary-purple;
      border: 1px solid $secondary-purple;
      .icon {
        color: #ffffff;
      }
    }
  }
  &.recruiting,
  .testing {
    border-top: 6px solid $primary-purple;
    .status {
      color: rgba(255, 255, 255, 0.8);
      background: $primary-purple;
      .icon {
        color: #ffffff;
      }
    }
  }
  &.analyzing,
  .published {
    border-top: 6px solid $primary-blue;
    .status {
      color: rgba(255, 255, 255, 0.8);
      background: $primary-blue;
      .icon {
        color: #ffffff;
      }
    }
  }
  .attributes,
  .status {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    text-align: center;
    color: #4f4f4f;
    font-size: 11px;
    padding: 0.5rem;
    width: 90px;
    float: left;
    .icon {
      display: block;
      margin: 0 auto;
      font-size: 22px;
      color: $secondary-purple;
    }
  }
}

.sku {
  color: $light-gray;
  font-size: 11px;
  letter-spacing: 3px;
}

.favorite {
  color: $light-gray;
  cursor: pointer;
}

.favorite i:last-of-type {
  color: $primary-purple;
  display: none;
}

.lob {
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .card-body {
    padding: 1.6rem 1.25rem;
  }
  img {
    height: 40px;
    margin-bottom: 1.5rem;
  }
  &:hover {
    //box-shadow: inset 0px 0px 0px 5px $primary-purple;
    transition: all 0.2s ease;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.125);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 1000%;
    background: $secondary-purple;
    opacity: 0;
    z-index: -1;
    transform-origin: 0% 0%;
    transform: translateX(calc(20% - 25px)) translateY(10%) rotate(-65deg);
    transition: transform 0.3s;
  }
  &:hover:after {
    //transform: translateY(10%) translateX(-25px) rotate(-45deg);
    transform: translateY(10%) translateX(-10px) rotate(-65deg);

    opacity: 0.2;
  }
}

.articles,
.article-wrapper {
  border-top: 6px solid $secondary-purple;
  background: #ffffff;
  padding: 3rem;
}

.article {
  .img-thumb-lg {
    width: 100%;
    img {
      width: 100%;
    }

    @media (min-width: 992px) {
      width: 320px;
      height: 200px;
      overflow: hidden;
    }
  }
  .img-thumb-sm {
    width: 130px;
    height: 120px;
    overflow: hidden;
    text-align: right;
    img {
      width: auto;
      height: 120px;
      margin-left: -100%;
    }
  }
}

.current-item {
  color: $green;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.modal-header {
  border-bottom: 0px;
}

.IS_callout {
  font-size: 0.8rem;
  color: $white;
  margin-top: -3.5rem;
  padding-bottom: 1rem;
}
