/* This is the homepage*/

.hero{
  height: 400px;
  background-size: cover;
  background-position: center;
  color: $white;
  h1,h4{
    color: $white;
  }
  h4{
    font-weight: 400;
    a{
      color: $white;
    }
  }
  .hero-banner-image-wrapper {
    height: 100%;
    width: 100%;
  }
  
   
}

.hero-gradient{
  height: 100%;
  width: 100%;
background: -moz-linear-gradient(left,  rgba(107,90,140,0.85) 0%, rgba(107,90,140,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(107,90,140,0.85) 0%,rgba(107,90,140,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(107,90,140,0.85) 0%,rgba(107,90,140,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d96b5a8c', endColorstr='#006b5a8c',GradientType=1 ); /* IE6-9 */
}





/* LOB--Category Backgrounds */
.offerings {
  width: 85px;
  height: 85px;
  margin: 0 auto 15px auto;
}
.checking {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-checking.png");
}
.savings {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-savings.png");
}
.credit-cards {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-credit-cards.png");
}
.loans {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-loans.png");
}
.retirement {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-retirement.png");
}
.insurance {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-insurance.png");
}
.business-funding {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-business-funding.png");
}
.industry-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-industry-solutions.png");
}
.cash-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-cash-management.png");
}
.growth-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-growth-management.png");
}
.global-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-global-solutions.png");
}
.research {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-research.png");
}
