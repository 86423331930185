/* Sticky footer styles -----
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}


.footer {
  background-color:$primary-blue;
  font-size: .9rem;
 
    
      .footer-logo {
        height:40px;
        width:40px;
        background:url('https://makana-health-mc.s3.us-east-2.amazonaws.com/MakanaHealth-secondary-logo-02.png') no-repeat;
        background-size: 40px;
        margin: 0 auto;
      }
      .social-links {
          padding: 0;
        
        li {
          margin: 8px 10px 3rem 0;
          display: inline-block;
          opacity:0.5;
          padding:0;
          color: #ffffff;
          &:hover,
          &:focus {
            opacity:1;
            transition: all 0.1s ease-in-out;
            cursor: pointer;
          }
        }
        
      }

      .menu-header, .menu-header a{
        color: $white;
        font-weight: 600;
      }

      .menu-copy{
        color: $light-gray;
      }
      .text-links {
          padding-top:.5rem;
          color:$light-gray;
    
        a {
          color:$light-gray;
      
        
          &:hover,
          &:focus {
            text-decoration: none;
            color:$secondary-purple;

          }
        }
      }
    
  }
  .disclaimer {
    font-size: .8rem;
    color: #eeeeee;
    width: 100%;
    background-color: #5E657A;
    padding-left: 1rem;
    padding-right: 1rem;
    a {
      text-decoration: none;
      color: $secondary-purple;
      &:hover {
        color: $secondary-purple;
      }
    }
    .list-inline{
      padding-top: 3px;
      margin-bottom: 0;
    }
  }
  .list-inline {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    .list-inline-item:not(:last-child) {
      margin-right: 0.5rem;
    }
    .list-inline-item {
      display: inline-block;
      color: $white;
      text-decoration: none;
      font-weight: 100;
    }
    .list-inline-item>a {
      color: $light-gray;
      text-decoration: none;
      font-weight: 100;
      color: $secondary-purple;
      &:hover {
        color: $secondary-purple;
      }
    }
  }

  #onetrust-pc-sdk{
    max-width: 960px !important;
    width: 100%  !important;
  }

  #onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc {
    background-color: #fff;
  }
  .ot-sdk-container{
    a, .ot-link-btn, .ot-always-active{ 
      color: $secondary-purple !important;
      font-weight: bold;
    }
  }

  #onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr, #onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header{
    color: $primary-blue !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob{
  background-color:$secondary-purple !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler,
div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler,
div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent #onetrust-accept-btn-handler
{
    background-color:$primary-purple !important;
      border-color: $primary-purple !important;
  }

  #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler.optanon-ghost-button,
  #onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link{
    color:$primary-purple !important;
  }

  #onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu, 
  #onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    border-color: $primary-purple !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li .category-menu-switch-handler h3{
  color: #4f4f4f;
}


.icon-cpra{
  width: 30px;
  height: 15px;
}

.newsletter-message{
  margin: 0 auto;
  width: 100%;
  padding: 10px 0;
  color: #ffffff;
  &.success{
    background-color: #70C28D;
  }
  &.error{
    background-color: #D8A0A0;
  }
}
#newsletterform{
  display: flex;
     margin-block-end: 0;
 }

 


