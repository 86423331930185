// Gallery
.goverlay {
  background: rgba(0, 0, 0, 0.3) !important; /* Light transparent overlay */
}
.gprev {
  left: 300px !important;
}
.gnext {
  right: 300px !important;
}

.eczema_text {
  flex-grow: 1;
  min-height: 55px;

  @media (min-width: 992px) {
    min-height: 95px;
  }
}
.dosage_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content is evenly spread */
  height: 100%; /* Makes sure all cards grow evenly */
}
.dosage_text {
  flex-grow: 1;
  min-height: 130px;
}
.dosage_btn {
  margin-top: auto;
}
.efficacy_text {
  flex-grow: 1;
  min-height: 80px;

  @media (min-width: 992px) {
    min-height: 90px;
  }
}
.diaxa-table-custom th {
  border: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #dee2e6;
  border-bottom: none !important;
  vertical-align: baseline !important;
}
.diaxa-table-custom td {
  vertical-align: middle !important;
  border: 1px solid #dee2e6;
  font-size: 14px;
  color: $primary-blue;
}
.diaxa-table-custom .dosage-header {
  font-size: 12px;
  font-weight: normal;
  color: #666;
}
.diaxa-table-custom .dosage-age {
  display: block;
  font-size: 12px;
}
.diaxa-table-custom tbody tr:nth-of-type(even) {
  background-color: #eaeaea; /* Light grey */
}
.diaxa-table-custom tbody tr:nth-of-type(odd) {
  background-color: #ffffff; /* White */
}
.diaxa-table-custom tbody tr {
  height: 75px; /* Adjust height as needed */
  display: table-row; /* Ensures proper table structure */
  vertical-align: middle;
}
.diaxa-table-primary-purple {
  background-color: $primary-purple;
  color: #fff !important;
}
.diaxa-table-secondary-purple {
  background-color: $secondary-purple;
  color: #fff !important;
}

.content-text {
  color: #666;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.adolescent-table {
  width: 700px;
  @media (min-width: 992px) {
    width: 100%;
  }
}

.adolescent-text {
  color: #666;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
}

@media (min-width: 992px) {
  .portfolio-grid {
    margin-left: 30px;
  }

  .trial-section {
    display: flex;
    justify-content: flex-end;
    max-width: 35%;
  }
}

.patient-support_highlight {
  display: block;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $secondary-purple;
  background: #dbd6e4;
  word-wrap: break-word;

  @media (min-width: 992px) {
    display: flex;
    padding: 15px 0px;
  }
}

.webinars-card {
  min-height: 85px;

  @media (min-width: 992px) {
    min-height: 100px;
  }
}
.webinars-sub {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  font-size: 14px;
}

.small-text,
.small-text p,
.small-text label,
.small-text em {
  font-size: 14px;
  line-height: 22px;
}

.small-text ul li {
  font-size: 14px;
  line-height: 27px;
}

.card-list li {
  line-height: 28px;
}

.study-results_hero {
  background-position-x: 630px;
  @media (min-width: 992px) {
    background-position-x: 0;
  }
}
.pharma_hero {
  background-position-x: 780px;
  @media (min-width: 992px) {
    background-position-x: 0;
  }
}
