.form-wrapper, .pdp-wrapper {
	background:$white;
	border-top: 6px solid $secondary-purple;
	padding: 2rem;
	.form-group {
		margin-bottom: 2rem;
	}
}


.input-group {
	border-radius: 3px;
	-webkit-appearance: none;
	&::placeholder{
		color: $light-gray;
		font-style: italic;
		font-weight: 300;
	}
	.form-control-group{
		border: 0px;
		padding: 12px;
	&::placeholder{
		color: $light-gray;
		font-style: italic;
		font-weight: 300;
	}
	}
}


.form-control {
	border: 1px solid $extra-light-gray;
	background-color:$white;
	border-radius: 3px;
	padding: 12px;
	&::placeholder{
		color: $light-gray;
		font-style: italic;
		font-weight: 300;
	}
}

.form-control.search-icon::after{
	content: "hi";
	display: block;
}


.input-group-text{
	background-color: transparent;
	color: $light-gray;
	border: none;
}


.form-control.select {
	padding:0.375rem 0.75rem;
	  background: #fff ;
	option{
		color: $light-gray;
		font-style: italic;
	}
}

.select option{
		color: $light-gray;
		font-style: italic;
	
}

.form-control-search {
	border: 0px solid transparent;
	background-color:transparent;
	padding-left:12px;
	font-size: .9rem;
	&::placeholder{
		color: $light-gray;
	}	

}

.form-control.contact {
	border-radius: 12px;
	margin-bottom: 20px;
	padding: 16px;
}


input[type='radio']:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -4px;
	left: -1px;
	position: relative;
	background-color: #ffffff;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 1px solid $light-gray;
}

input[type='radio']:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -4px;
	left: -1px;
	position: relative;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 5px solid $primary-purple;
}

.slds-checkbox{
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before{
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 1px solid #ADB5BD;
  background: #fff;
  border-radius: 4px;
}


[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.5em; height: 1.5em;
  border: 1px solid #fff;
  background: $secondary-purple;
  border-radius: 4px;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .25em;
  font-size: 1.4em;
  line-height: 0.8;
  color: $white;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px solid $secondary-purple;
}




.form-check-inline{
	margin-right: 1.5rem;
}


/*progressbar*/
#progressbar {
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	/*CSS counters to number the steps*/
	counter-reset: step;
	-webkit-padding-start: 0px;
	-moz-padding-start: 0px;
	width: 60%;
}
#progressbar li {
	list-style-type: none;
	color: $dark-gray;
	font-size: 14px;
	width: 25%;
	float: left;
	position: relative;
}
#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 42px;
	line-height: 42px;
	display: block;
	font-size: 20px;
	color: white;
	background: $light-gray;
	border-radius: 42px;
	margin: 0 auto 5px auto;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
	background: $green;
	color: white;
}


.ui-datepicker{
	background: #ffffff;
	border: 1px solid #f2f2f2;
	max-width: 300px;
	width: 100%;
	padding-left: 1.25rem;
	padding-right: 1.25rem;

  }
  .ui-datepicker-calendar{
	width: 100%;
  }
  .ui-datepicker-prev, .ui-datepicker-next{
	color: $secondary-purple;
	cursor: pointer;
	font-size: 90%;
}
  .ui-datepicker-next{
	  float: right;
  }
  .ui-datepicker-title{
	  text-align: center;
  }

  .disclosure {
	width: 100%;
	opacity: 60%;
	padding: 1rem 2rem 0;
	a{
		color: $secondary-purple;
	}
}
