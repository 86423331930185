/* Landing Pages Template
-------------------------------------------------- */
/* ======================= LP Overrides ===================== */
body {
	position: relative;
	scroll-behavior: smooth;

  }

.product-lp{

.navbar{
	background-color: $white;
	.navbar-nav>li {
		color: $primary-blue;
		font-size: .9rem;
		display: block;
		padding: 1rem 1.5rem;
	}
	.navbar-nav>li>a{
		padding: 0;
		color: $primary-blue;
	}
}

.x2{
	background-image: url('https://makana-health-mc.s3.us-east-2.amazonaws.com/MakanaHealth-X2-02.png');
}
	

.subnav{
	background-color: $primary-blue;
	padding: .8rem 1rem;
	ul{
		margin-bottom: 0;
		padding-inline-start: 0px;
	}
	li {
		font-size: .75rem;
		display: block;
		padding-right: 1.5rem;
	}
	li>a{
		padding: 0;
		color: #adb5bd;
		&:hover{
			color: $secondary-purple;
		}
	}
	.for-hcp img{
		width: 20px;
		height: auto;
		display: inline-block;
		margin-top: -3px;
		margin-right: 4px;
	}
}

.hero{
	height: 500px;
	h1, h5, p{
		color: $white;
		text-shadow: 0px 2px 6px rgba(0,0,0,.2);
	}
}

.icon{
	width: 85px;
}

}

/* ======================= JUMBOTRONs ===================== */

.category-splash{
	background: $primary-purple;
	background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/MK-category-bg.png");
	background-size: cover;
	background-position: center top;
	h1{
		color: $white;
		text-transform: capitalize;
		img{
			width: 48px;
			display: inline-block;
			margin-right: 1rem;
			padding-bottom: 10px;
		}
	}
	h4, h5{
		font-weight: 400;
		color: $white;
		a{
		  color: $white;
		}
	  }
}

.product-splash{
	background: $primary-purple;
	background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/MK-product-bg.png");
	background-size: cover;
	background-position: center;
	h1{
		color: $white;
		/*text-transform: capitalize;*/
		img{
			width: 48px;
			display: inline-block;
			margin-right: 1rem;
			padding-bottom: 10px;
		}
	}
	h4, h5{
		font-weight: 400;
		color: $white;
		a{
		  color: $white;
		}
	  }
}

#x2-hero{
	background-image: url('https://makana-health-mc.s3.us-east-2.amazonaws.com/Banners/2021_MK_MedDevice_X2_LP_optimized.jpg');
}

.hero-graphic{
	width: 100%;
	height: 100%;
	background-image: url('https://makana-health-mc.s3.us-east-2.amazonaws.com/Banners/2021_MK_MedDevice_X2_design1.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.meter-logo{
	color: $white;
	text-shadow: 10px 0px 0px $primary-purple;
	font-size: 140px;
	line-height: 140px;
}

.meter-img{
	width: 100%;
	max-width: 222px;
	img{
		max-width: 100%;
	}
}




.safety-info{
	background-color: $extra-light-gray;
	border-top: 5px solid $primary-blue;
}

/* ======================= STRUCTURE ===================== */




.right-pattern, .left-pattern{
	padding-bottom: 1rem;
}

@media (max-width: 768px) {
	.subnav {
	  display: none !important;
	}
	.product-lp{
		.navbar .navbar-nav>li>a.nav-link, .navbar .navbar-nav>li{
			color: #adb5bd;
		}
		
	}

	.meter-img{
		margin: -80px auto 10px;
	}
  
  }


@media (min-width: 768px) {

	.meter-img{
		margin: -200px auto 10px;
	}

	.right-pattern{
			background-image: url('https://makana-health-mc.s3.us-east-2.amazonaws.com/Banners/2021_MK_section_pattern1.png');
			background-position: right;
			background-repeat: no-repeat;
			background-size: contain;
			padding-top: 5rem;
			padding-bottom: 5rem;
			background-color: $extra-light-gray;
		}
		.left-pattern{
			background-image: url('https://makana-health-mc.s3.us-east-2.amazonaws.com/Banners/2021_MK_section_pattern2.png');
			background-position: left;
			background-repeat: no-repeat;
			background-size: contain;
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
  
  }
  
  
  
