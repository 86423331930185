/* Inside Pages Template
-------------------------------------------------- */



/* ======================= JUMBOTRONs ===================== */

.category-splash{
	background: $primary-purple;
	background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/MK-category-bg.png");
	background-size: cover;
	background-position: center top;
	h1{
		color: $white;
		text-transform: capitalize;
		img{
			width: 48px;
			display: inline-block;
			margin-right: 1rem;
			padding-bottom: 10px;
		}
	}
	h4, h5{
		font-weight: 400;
		color: $white;
		a{
		  color: $white;
		}
	  }
}

.product-splash{
	background: $primary-purple;
	background-image: url("https://makana-health-mc.s3.us-east-2.amazonaws.com/MK-product-bg.png");
	background-size: cover;
	background-position: center;
	h1{
		color: $white;
		/*text-transform: capitalize;*/
		img{
			width: 48px;
			display: inline-block;
			margin-right: 1rem;
			padding-bottom: 10px;
		}
	}
	h4, h5{
		font-weight: 400;
		color: $white;
		a{
		  color: $white;
		}
	  }
}


.article-splash{
	background: $primary-purple;
	background-size: cover;
	background-position: center;
	min-height: 300px;
}



.retail-banking-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-banking-overview-hero.jpg);
	background-size:cover;
	background-position:center center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-borrowing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-borrowing-overview-hero.jpg);
	background-size:cover;
	background-position:center center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-investing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-investing-overview-hero.jpg);
	background-size:cover;
	background-position:top center;
	margin-bottom: 0px;
	margin-top: -65px;
}


.corporate-banking-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-banking-overview-hero.jpg);
	background-size:cover;
	background-position:right center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.corporate-borrowing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-borrowing-overview-hero.jpg);
	background-size:cover;
	background-position:right center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.featured-article-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-research-and-insights-hero.jpg);
	background-size:cover;
	background-position:50% 60%;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-news-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-news-hero.jpg);
	background-size:cover;
	background-position:50% 60%;
	margin-bottom: 0px;
	margin-top: -65px;
}



/* ======================= STRUCTURE ===================== */
.inside-intro-content {
	background:url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond.png");
	text-align:center;
	padding: 50px 0;
		.offerings {
			width: 85px;
			height: 85px;
			margin: 40px auto 0px auto;
		}
		h1 {
			padding: 10px 0;
		}
		h3 {
			margin-top:0px;
		}
		h4 {
			margin:20px 0;
		}
}

.product-intro {
	margin:50px auto 25px auto;
		h1 {
			margin-top: 0;
			padding: 10px 0 0 0;
		}
		h3 {
			margin-top:0px;
			margin-bottom: 20px;
		}
		h4 {
			margin:20px 0;
		}
}



.attributes{
	border:1px solid rgba(0,0,0,.125);
	border-radius: 8px;
	text-align: center;
	color: #4f4f4f;
	font-size: 11px;
	padding: .5rem;
	width: 90px;
	float: left;
	.icon{
		display: block;
		margin: 0 auto;
		font-size:22px;
		color: $secondary-purple;
	}
	
}


.progress{
	margin-top: 2rem;
	margin-bottom: 1rem;
	border-radius: 10px;
	height: 8px;
	background-color: $extra-light-gray;
}
.progress-bar{
	background-color: $primary-purple;
}


.one, .two, .three, .four{
    position:absolute;
	z-index:1;
	margin-top:-5px;
	height:16px;
	width:16px;
	border-radius:16px;
}
.one{
	left:10%;
}
.two{
	left:34%;
}
.three{
	left:61%;
}

.four{
	left:90%
}
.primary-color{
	background-color: $primary-purple;
}
.no-color{
	background-color:inherit;
}

.active{
	width: 30px;
	height: 30px;
	border-radius: 30px;
	margin-top: -13px;
	color: #ffffff;
	padding: 8px 0;
	font-size: 15px;
	text-align: center;
}
