
/* ======================= MEDIA QUERIES ===================== */
@media  (min-width: 768px) and (max-width:991px) {
  .navbar-header {
      .nav > li > a {
        position: relative;
        display: block;
        padding: 10px 6px;
        font-size: 95%;
      }
    }
    .navbar .navbar-brand {
      margin: -5px 20px 0 0;
    }

 
  }

  
@media  (min-width: 768px) {

    div.col-sm-7.five-three {
    width: 60% !important;
    }

    div.col-sm-5.five-two {
    width: 40% !important;
    }

  #nav-expander {
    display:none;
  }
  .navbar-brand-mobile {
    display:none;
  }
  .navbar {
    border-radius: 0;
  }

  .lob .card-body {
    padding: 2.2rem 0rem;
}

}

@media  (max-width: 1020px) {

  .nav-search{
    display:none;
  }


  .category-menu {
    display:none;
  }
  .navbar {
    z-index: 100;
    display:flex;
   
    .navbar-brand{
      margin-left: .5rem;
    }
    .navbar-toggler{
      margin-right: .5rem;
    }
   
  }

  .nav-search {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-inverse {
    background-color:$primary-blue;
    opacity:1;
    .navbar-left {
      display:none;
    }
  }
}

@media  (max-width: 960px) {

    .moveOver {
      margin-left:0px;
    }
    #msform {
      width: 95%;
    }
    #msform input[type="text"], #msform input[type="password"], #msform select  {

      width: 100%;
      margin-left: auto;
      margin-right: auto;

    }
    #progressbar {
      margin-bottom: 25px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      /*CSS counters to number the steps*/
      counter-reset: step;
      -webkit-padding-start: 0px;
      width: 90%;
    }
    #progressbar li {
      list-style-type: none;
      color: $dark-gray;
      font-size: 9px;
      width: 25%;
      float: left;
      position: relative;
    }

    .built-on{
      display: none;
    }

    .col-sm-6:first-of-type .article{
      padding-bottom: 1.5rem;
    }
    .navbar-collapse.in {
      width:100%;
      background: $primary-blue;
      position: absolute;
      top: 64px;
      left: 0px;
      z-index: 10;
      
      
    }

    .cta.newsletter, .cta.enrollment, .cta.mobileapp{
      background-image: none;
    }


    .articles, .spotlight, .form-wrapper, .article-wrapper{
      padding: 1.25rem;
      .row{
        margin: auto 0;
      }
    }

    .btn{
      display: block;
      width: 100%;
    }

    .attributes.status{
      margin-top:1rem;
    }


}


@media (max-width: 980px) {
  .banking-categories, .overview-products{
    padding: 50px 0 0 0;
    .col-sm-4, .col-sm-6, .col-md-4{
      margin-bottom:45px;
    }
  	p {
  		min-height:0;
  	}

  }

  .trending-topics {
    padding: 50px 0 25px 0;
    .col-md-4 {
      margin-bottom:45px;
    }
    p {
      min-height: 0;
    }
  }

  .inside-intro-content {
    p {
      min-height: 0;
    }
  }

  .inside-intro-content, .overview-products {
    .col-md-4, .col-md-3, .col-md-4 {
      margin-bottom:45px;
    }
    h4 {
      min-height: 0;
    }
  }

  .jumbotron-content {
    margin-top:95px;
    h1 {
      font-size:27pt;
    }
  }

  .app-btns {
    .col-md-4 {
      padding-top:15px;
    }
  }

  .footer  {
    .newsletter {
      .container {
      
        height: auto;
        padding: 25px;
        .newsletter-icon {
          display:none;
        }
        p {
          margin-bottom:20px;
          text-align: center;
        }
      }
    }
  }


}


@media  (min-width: 1200px) {
  .lob .card-body {
    padding: 2.2rem 1.25rem;
}
}


@media (max-width: 768px) {
  .navbar {
    padding: .5rem 0;
    z-index: 100;
    display:flex;
    .navbar-collapse{
      background: $primary-blue;
    }
  }

}




@media (max-width: 620px) {

    .hero{
      height: 500px;
    }

  }

